<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/buffer/spi_aoi_ng_buffer"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Buffer/SPI AOI NG Buffer.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  SPI / AOI NG Buffer
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/buffer/cooling_buffer"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Buffer/COOLING BUFFER.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  COOLING BUFFER
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/buffer/chain_buffer"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Buffer/CHAIN BUFFER.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CHAIN BUFFER
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/buffer/magazine_buffer"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Buffer/MAGAZINE BUFFER.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  MAGAZINE BUFFER
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/buffer/rack_buffer"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Buffer/RACK BUFFER.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title"> 
                  RACK BUFFER
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/board_handling_system/buffer/curing_chain_buffer"
          >
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Buffer/CURING CHAIN BUFFER.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CURING CHAIN BUFFER
                </h4>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../../components/Header.vue";

import Footer from "../../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>